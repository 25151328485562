export const API_LIFESITUATION_MODEL = {
    entity: 'lifesituations',
    url: 'lifesituations/',
    methods: {
        generateIdentifier: {
            url: 'get_identifier/'
        },
        dataDownload: {
            url: 'data_download/'
        },
        importData: {
            url: 'import_data/'
        }
    },
}
