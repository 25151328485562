const apiConfig = {
    baseUrl: 'https://belreestr.ru/api/main/',
    baseUrlContragent: 'https://belreestr.ru/',
    baseUrlMedia: 'https://belreestr.ru/media/',
    apiKey: ''
}

// const apiConfig = {
//     baseUrl: 'http://51.250.126.124:8000/api/main/',
//     baseUrlContragent: 'http://51.250.126.124:8000/',
//     baseUrlMedia: 'http://51.250.126.124:8000/media/',
//     apiKey: ''
// }

export default apiConfig;