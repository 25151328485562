import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { LifeSituationActionCreators } from '../../store/reducers/lfieSituation/action-creatorlife';
import './styles.scss';

interface Option {
    display_name: string;
    value: string;
}

interface InputWithResetProps {
    options: Option[];
    placeholder: string;
    error?: string;
    onReset: () => void;
    onChange: (value: string) => void;
    value: string;
}

const InputWithReset: React.FC<InputWithResetProps> = ({
                                                           options,
                                                           placeholder,
                                                           error,
                                                           onReset,
                                                           onChange,
                                                           value,
                                                       }) => {
    const [inputValue, setInputValue] = useState<string>(options.find(option => option.value === value)?.display_name || '');
    const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false);

    const dispatch = useDispatch();

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setInputValue(newValue);
        setIsDropdownVisible(true);
    };

    const handleOptionClick = (option: Option) => {
        setInputValue(option.display_name);
        onChange(option.value);
        setIsDropdownVisible(false);
    };

    const handleReset = () => {
        setInputValue('');
        onReset();
        dispatch(LifeSituationActionCreators.setOrgId(''));
    };

    return (
        <div className="inputWithReset">
            <input
                type="text"
                className={`input ${error ? 'inputError' : ''}`}
                value={inputValue}
                onChange={handleInputChange}
                placeholder={placeholder}
                onBlur={() => setTimeout(() => setIsDropdownVisible(false), 200)} // скрытие выпадающего списка при потере фокуса
                onFocus={() => setIsDropdownVisible(true)} // показываем выпадающий список при фокусе
            />
            {isDropdownVisible && (
                <ul className="dropdown">
                    {options
                        .filter(option => option.display_name.toLowerCase().includes(inputValue.toLowerCase()))
                        .map(option => (
                            <li
                                key={option.value}
                                onClick={() => handleOptionClick(option)}
                            >
                                {option.display_name}
                            </li>
                        ))}
                </ul>
            )}
            <button className="resetButton" onClick={handleReset}>
                Сбросить
            </button>
        </div>
    );
};

export default InputWithReset;