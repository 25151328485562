import React, {FC, useEffect, useState} from "react";
import {ILifeSituation} from "../../models/ILifeSituation";
import "./styles.scss";
import LifeSituationServiceItem from "./LifeSituationServiceItem/LifeSituationServiceItem";
import {useDispatch} from "react-redux";
import Buttons from "../Buttons/Buttons";
import icons from "../../assets/icons/icons";

interface LifeSituationDataProps {
    lifeSituation: ILifeSituation[] | undefined;
    lifeSituationOption: any;
    setUpdate: (e: boolean, id: string) => void;
}

const LifesituationsComponent: FC<LifeSituationDataProps> = ({
                                                                 lifeSituation,
                                                                 lifeSituationOption,
                                                                 setUpdate,
                                                             }) => {
    const [selectedCards, setSelectedCards] = useState<string[]>([]);
    const dispatch = useDispatch();
    console.log('lifeSituationOption', lifeSituationOption)
    const toggleCardSelection = (id: string) => {
        if (selectedCards.includes(id)) {
            setSelectedCards(selectedCards.filter((cardId) => cardId !== id));
        } else {
            setSelectedCards([...selectedCards, id]);
        }
    };

    return (
        <div className="lifeCituationContainer">
            {lifeSituation && lifeSituation.length > 0 ? (
                lifeSituation.map((item) => (
                    <div
                        key={item.id}
                        className={`lifeContinaerItem ${
                            item?.id && selectedCards.includes(item.id) ? "active" : ""
                        }`}
                    >
                        <div className="cardLifeSituation">
                            <div
                                className="cardHeader"
                                onClick={() => item?.id && toggleCardSelection(item.id)}
                            >
                                <h3 className="cardHeaderSubtitle">
                                    {item.subname || "Жизненная ситуация"}
                                </h3>
                                <h1 className="cardHeaderTitle">{item.name}</h1>
                            </div>
                            <div className="cardFooter">
                                <p className="cardFooterNumber">{item.identifier || ""}</p>
                                <div className="containerButtonCard">
                                    {
                                        item.is_best_practice_service_count && (
                                            <Buttons
                                                className="myButton"
                                                text={`${item.is_best_practice_service_count}` || ""}
                                                toolTip={"Кол-во лучших практик"}
                                                onClick={() => {
                                                }}
                                            />
                                        )
                                    }


                                    <Buttons
                                        className="whiteIco buttoPosition"
                                        ico={icons.edit}
                                        text={""}
                                        toolTip={"Редактировать"}
                                        onClick={() => {
                                            setUpdate(true, item.id || "");
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        {selectedCards.includes(item.id ? item.id : "") && (
                            <LifeSituationServiceItem
                                lifeSitaitonsId={item.id}
                                services={item.services}
                                servicesOption={
                                    lifeSituationOption?.find(
                                        (option: { key: string }) => option.key === "list"
                                    )?.value
                                }
                            />
                        )}
                    </div>
                ))
            ) : (
                <div className="noLifeSituationsMessage">
                    Ничего нет
                </div>
            )}
        </div>
    );
};

export default LifesituationsComponent;
