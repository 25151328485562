import React, {FC, useEffect, useState} from "react";
import Tabs from "../Tabs/Tabs";
import InputSearch from "../InputSearch/InputSearch";
import "./styles.scss";
import Buttons from "../Buttons/Buttons";
import LifesituationsComponent from "../LifesituationsComponent/LifesituationsComponent";
import LifesituationsApiRequest from "../../api/Lifesituation/Lifesituation";
import {useDispatch} from "react-redux";
import {LifeSituationActionCreators} from "../../store/reducers/lfieSituation/action-creatorlife";
import {ILifeSituation} from "../../models/ILifeSituation";
import {useTypeSelector} from "../../hooks/useTypedSelector";
import Modal from "../Modal/Modal";
import FormInput from "../FormInput/FormInput";
import {fieldToArray} from "../UI/functions/functions";
import {DataPressActionCreators} from "../../store/reducers/dataPressItem/action-creator";
import {IDataPress} from "../../models/IDataPress";
import LifeSituationServiceItem from "../LifesituationsComponent/LifeSituationServiceItem/LifeSituationServiceItem";
import ServicesApiRequest from "../../api/Services/Services";
import ErrorMessage from "../UI/ErrorMassage/ErrorMassage";
import InputWithReset from "../InputWithReset/InputWithReset";
import OrganizationApiRequest from "../../api/Organization/Organization";
import OrganizationsComponent from "../OrganizationsComponent/OrganizationsComponent";


const ReestrComponent: FC = () => {
    const lifeSituationApi = new LifesituationsApiRequest();
    const organizationApi = new OrganizationApiRequest();
    const ServicesListApi = new ServicesApiRequest();
    const {lifeSituation, isUpdate, orgId, bestPractic} = useTypeSelector(
        (state) => state.lifeSituationReducer
    );
    const {dataPress} = useTypeSelector((state) => state.dataPressReducer);
    const dispatch = useDispatch();
    const [activeTabId, setActiveTabId] = useState<number>(1);
    const [isVisibleModal, setIsVisibleModal] = useState<boolean>(false);
    const [identifier, setIdentifier] = useState<string>("");
    const [lifeSituationOption, setLifeSituationOption] = useState<any>(null);
    const [isUpdateData, setIsUpdateData] = useState<any>({});
    const [error, setError] = useState<string>("");
    const [searchTerm, setSearchTerm] = useState("");
    const storedUser = localStorage.getItem("user");
    const user = storedUser ? JSON.parse(storedUser) : null;
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [nextPage, setNextPage] = useState<string | null>(null);
    const [prevPage, setPrevPage] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [organizations, setOrganizations] = useState<[]>([]);

    const tabsData = [
        {
            id: 1,
            text: "Жизненные ситуации",
            content: "",
        },
        // {
        //   id: 2,
        //   text: "Услуги",
        //   content: "",
        // },
    ];

    const filtering = (page: number = 1) => {
        setIsLoading(true);

        let queryParams = [];

        if (page != 1)
            queryParams.push(`page=${page}`);
        else
            setCurrentPage(1);

        if (orgId) {
            queryParams.push(`organization=${orgId}`);
        }

        if (searchTerm) {
            queryParams.push(`search=${searchTerm}`);
        }


        const queryString =
            queryParams.length > 0 ? `?${queryParams.join("&")}` : "";

        if (bestPractic) {
            organizationApi.list({urlParams: queryString}).then((resp) => {
                if (resp.success) {
                    console.log(resp.data);
                    // @ts-ignore
                    setOrganizations(resp.data?.results);
                    // @ts-ignore
                    setNextPage(resp.data?.next || null);
                    // @ts-ignore
                    setPrevPage(resp.data?.previous || null);
                }
                setIsLoading(false);
            });
        } else {
            lifeSituationApi
                .list({
                    urlParams: queryString,
                })
                .then((resp) => {
                    if (resp.success) {
                        dispatch(
                            LifeSituationActionCreators.setLifeSituation(
                                // @ts-ignore
                                resp.data?.results as ILifeSituation[]
                            )
                        );
                        // @ts-ignore
                        setNextPage(resp.data?.next || null);
                        // @ts-ignore
                        setPrevPage(resp.data?.previous || null);
                    }
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }

    }

    useEffect(() => {
        filtering();
    }, [isUpdate, orgId, bestPractic]);

    useEffect(() => {
        lifeSituationApi.options().then((resp) => {
            if (resp.success) {
                //@ts-ignore
                const options = fieldToArray(resp?.data?.actions);
                setLifeSituationOption(options);

                isUpdateData?.id &&
                lifeSituationApi
                    .getById({id: isUpdateData.id + "/"})
                    .then((resp) => {
                        if (resp.success) {
                            //@ts-ignore
                            const dataLife = fieldToArray(resp.data ? resp.data : "");
                            setIsVisibleModal(true);
                            dataLife.map((item) => {
                                dispatch(
                                    DataPressActionCreators.setDataPress(item.key, item.value)
                                );
                            });
                        }
                    });
            }
        });

        if (isVisibleModal && Object.keys(isUpdateData).length === 0) {
            lifeSituationApi
                .generateIdentifier(`?organization=${orgId}`)
                .then((ident) => {
                    if (ident.success) {
                        dispatch(
                            DataPressActionCreators.setDataPress(
                                "identifier",
                                //@ts-ignore
                                ident?.data?.identifier || ""
                            )
                        );
                    }
                });
        }
    }, [isVisibleModal, isUpdateData]);

    const handleChangeInput = (field: string, value: string | boolean) => {
        dispatch(DataPressActionCreators.setDataPress(field, value));
    };

    const addLifeSituation = (type?: string) => {
        //@ts-ignore
        if (dataPress?.name) {
            type === "update"
                ? lifeSituationApi
                    .update({
                        urlParams: isUpdateData.id ? isUpdateData.id + "/" : "",
                        body: dataPress,
                    })
                    .then((resp) => {
                        if (resp.success) {
                            setIsVisibleModal(false);
                            setIsUpdateData({});
                            dispatch(LifeSituationActionCreators.setUpdate(!isUpdate));
                        }
                    })
                : lifeSituationApi
                    .create({
                        body: {
                            ...dataPress,
                            organization: orgId !== "" ? orgId : user.organization,
                        },
                    })
                    .then((resp) => {
                        if (resp.success) {
                            setIsVisibleModal(false);
                            dispatch(LifeSituationActionCreators.setUpdate(!isUpdate));
                        }
                    });

            dispatch(DataPressActionCreators.clearDataPress());
        } else {
            setError("Не выбранна жизненная ситуация");
        }
    };

    const handleResetOrganization = () => {
        dispatch(LifeSituationActionCreators.setOrgId(""));
        dispatch(LifeSituationActionCreators.setUpdate(!isUpdate));
    };

    const handlePageChange = (url: string | null) => {
        console.log('url', url)
        if (url) {
            var page = url.includes('page=') ? parseInt(url.split('page=')[1].split('&')[0]) : 1
            setCurrentPage(page);

            console.log('page', page)
            console.log('currentPage', currentPage)
            filtering(page);
        }

    };

    const handleSearchChange = (e: string) => {
        setSearchTerm(e);
    };

    // @ts-ignore
    return (
        <>
        {error != "" && (
            <ErrorMessage
                type={"error"}
                message={error}
                onClose={() => {
                    setError("");
                }}
                onClick={() => {
                    addLifeSituation(isUpdateData.id ? "update" : "");
                }}
            />
        )}
        {isVisibleModal && (
            <Modal
                content={
                    <div>
                        <h1>Добавить жизненную ситуацию</h1>
                        {lifeSituationOption != null &&
                            lifeSituationOption.map((item: any) => {
                                if (item.key === "create") {
                                    const optionData = fieldToArray(item.value);
                                    return (
                                        <div key={item.key}>
                                            {optionData.map((data) => {
                                                if (data.key === "organization") {
                                                    return;
                                                } else {
                                                    return (
                                                        <FormInput
                                                            key={data.key}
                                                            style={"formMini"}
                                                            value={
                                                                //@ts-ignore
                                                                dataPress[data.key]
                                                            }
                                                            disabled={
                                                                data.key === "identifier" ? true : undefined
                                                            }
                                                            onChange={(e) => {
                                                                handleChangeInput(data.key, e);
                                                            }}
                                                            options={data.value.choices}
                                                            subInput={data.value.label}
                                                            error={error}
                                                            keyData={data.key}
                                                            required={data.value.required}
                                                            type={data.value.type}
                                                        />
                                                    );
                                                }
                                            })}
                                        </div>
                                    );
                                } else {
                                    return null; // Можете добавить обработку других случаев, если необходимо
                                }
                            })}
                        <div className="modalButtonContainer">
                            <Buttons
                                className="buttonModal_white"
                                text={"Отмена"}
                                onClick={() => {
                                    setIsUpdateData({});
                                    setIsVisibleModal(false);
                                    dispatch(DataPressActionCreators.clearDataPress());
                                }}
                            />
                            <Buttons
                                className="buttonModal"
                                text={"Добавить"}
                                onClick={() => {
                                    addLifeSituation(isUpdateData.id ? "update" : "");
                                }}
                            />
                        </div>
                    </div>
                }
                onClose={() => {
                    setIsUpdateData({});
                    dispatch(DataPressActionCreators.clearDataPress());
                    setIsVisibleModal(false);
                }}
            />
        )}

        <div className="reestrComponent">
            <div className="reestrNav">
                <Tabs tabsData={tabsData} activeTabId={setActiveTabId}/>
                <Buttons
                    text={bestPractic ? "Лучшие практики" : "Все практики"}
                    className="whiteButton"
                    onClick={() => {
                        dispatch(
                            LifeSituationActionCreators.setIsBestPractic(!bestPractic)
                        );
                    }}
                />
                {user?.is_admin &&
                    lifeSituationOption != null &&
                    lifeSituationOption.map((item: any) => {
                        if (item.key === "filters") {
                            const optionData = fieldToArray(item.value);
                            return (
                                <InputWithReset
                                    key={item.key}
                                    options={optionData.find((data) => data.key === "organization")?.value.choices || []}
                                    placeholder="Организация"
                                    value={orgId || ""}
                                    onChange={(value) => dispatch(LifeSituationActionCreators.setOrgId(value))}
                                    onReset={handleResetOrganization}
                                />
                            );
                        } else {
                            return null;
                        }
                    })}
                <div className="inputSearchContainer">
                    <div className="inputSearchContainer">
                        <input
                            type="text"
                            className="searchInput"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            placeholder="Введите слово для поиска…"
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    filtering();
                                }
                            }}
                        />


                        <Buttons
                            className="buttonSearch"
                            text={"Поиск"}
                            onClick={() => filtering()}
                        />
                    </div>
                </div>
                {!user?.is_admin && (
                    <Buttons
                        text={
                            activeTabId === 1
                                ? "Добавить жизненную ситуацию"
                                : "Добавить услугу"
                        }
                        className="whiteButton"
                        onClick={() => {
                            setIsVisibleModal(true);
                        }}
                    />
                )}
            </div>

            {isLoading ? (
                <div className="loading-indicator">
                    Загрузка...
                </div>
            ) : bestPractic ? (
                <OrganizationsComponent organizations={organizations} lifeSituationOption={lifeSituationOption}/>
            ) : (
                <LifesituationsComponent
                    lifeSituation={lifeSituation}
                    lifeSituationOption={lifeSituationOption}
                    setUpdate={setIsUpdateData}
                />
            )}


        {(nextPage || prevPage) && !isLoading ? (
            <div className="pagination">
                <button
                    onClick={() => handlePageChange(prevPage)}
                    disabled={!prevPage}
                    className={"button"}
                >
                    Назад
                </button>
                <span>Страница {currentPage}</span>
                <button
                    onClick={() => handlePageChange(nextPage)}
                    disabled={!nextPage}
                    className={"button"}
                >
                    Вперед
                </button>
            </div>
        ) : null}
        </div>
</>
)
    ;
};

export default ReestrComponent;
