import BaseModelAPI from "../BaseModelAPI";
import apiConfig from "../apiConfig";
import axiosClient from "../axiosClient";
import {API_Organization_MODEL} from "./const";

class OrganizationApiRequest extends BaseModelAPI {
    constructor() {
        super(API_Organization_MODEL.url);
    }

}

export default OrganizationApiRequest;
