import React, {useState, useRef} from 'react';
import axios from 'axios';
import apiConfig from "../../api/apiConfig";
import "./style.scss";

const UploadFileComponent = () => {
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [uploadError, setUploadError] = useState<string>('');
    const [uploadSuccess, setUploadSuccess] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);

    // Create a reference for the file input element
    const fileInputRef = useRef<HTMLInputElement>(null);

    const validMimeTypes = [
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ];

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            if (validMimeTypes.includes(file.type)) {
                console.log("File selected:", file);
                setSelectedFile(file);
                setUploadError(''); // Clear previous errors when a new file is selected
                setUploadSuccess(''); // Clear previous success message when a new file is selected
            } else {
                console.warn("Invalid file type selected");
                setUploadError('Пожалуйста, выберите файл формата Excel (.xls, .xlsx)');
                setSelectedFile(null);
            }
        } else {
            console.warn("No file selected");
            setSelectedFile(null);
        }
    };

    const handleFileUpload = async () => {
        console.log("Upload button clicked");

        if (selectedFile) {
            setIsLoading(true); // Set loading to true when the upload starts
            const formData = new FormData();
            formData.append('file', selectedFile);

            try {
                const response = await axios.post(`${apiConfig.baseUrl}lifesituations/import_data/`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                console.log('Response:', response);
                if (response.status === 200) {
                    setUploadSuccess('Данные успешно загружены и обновлены');
                    setUploadError('');
                    setSelectedFile(null);

                    // Reset the file input element
                    if (fileInputRef.current) {
                        fileInputRef.current.value = '';
                    }

                    // Automatically hide the success message after 5 seconds
                    setTimeout(() => {
                        setUploadSuccess('');
                    }, 5000);
                } else {
                    setUploadSuccess('');
                    setUploadError('Ошибка при загрузке файла');
                }
            } catch (error) {
                console.error('Error uploading file:', error);
                setUploadError('Ошибка при загрузке файла');
                setUploadSuccess('');
            } finally {
                setIsLoading(false); // Set loading to false when the upload completes
            }
        } else {
            console.error("No file selected for upload");
            setUploadError('Пожалуйста, выберите файл для загрузки');
            setUploadSuccess('');
        }
    };

    // Function to truncate file name to 26 characters
    const truncateFileName = (name: string) => {
        if (name.length > 26) {
            return `${name.slice(0, 23)}...`;
        }
        return name;
    };

    return (
        <div className="uploadFileContainer">
            <label className="fileInputLabel">
                {selectedFile ? truncateFileName(selectedFile.name) : "Выберите файл"}
                <input
                    type="file"
                    accept=".xls,.xlsx,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    onChange={handleFileChange}
                    disabled={isLoading} // Disable input while loading
                    ref={fileInputRef} // Attach the ref to the input element
                />
            </label>
            {selectedFile && (
                <button
                    className="uploadButton"
                    onClick={handleFileUpload}
                    disabled={isLoading} // Disable button while loading
                >
                    {isLoading ? "Загрузка..." : "Загрузить"} {/* Show loading text */}
                </button>
            )}
            {uploadError && (
                <div className="error-message">
                    {uploadError}
                </div>
            )}
            {uploadSuccess && (
                <div className="success-message">
                    {uploadSuccess}
                </div>
            )}
        </div>
    );
};

export default UploadFileComponent;