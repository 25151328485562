// action-creatorlife.ts
import { LifeSituationActionEnum, SetBestPracticAction, SetErrorAction, SetLifeSituationAction, SetOrgIdAction, SetUpdateAction } from "./types";
import { ILifeSituation } from "../../../models/ILifeSituation";
import { IErr } from "../../../models/IErr";

export const LifeSituationActionCreators = {
  setLifeSituation: (lifeSituation: ILifeSituation[]): SetLifeSituationAction => ({
    type: LifeSituationActionEnum.SET_LIFESITUATION,
    payload: lifeSituation,
  }),
  setOrgId: (payload: string): SetOrgIdAction => ({ type: LifeSituationActionEnum.SET_ORGID, payload }),
  setIsBestPractic: (payload: boolean): SetBestPracticAction => ({ type: LifeSituationActionEnum.SET_BEST, payload }),
  setErr: (payload: IErr): SetErrorAction => ({ type: LifeSituationActionEnum.SET_ERROR, payload }),
  setUpdate: (payload: boolean): SetUpdateAction => ({ type: LifeSituationActionEnum.SET_UPDATE, payload }),
};
