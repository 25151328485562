export const API_SERVICES_MODEL = {
    entity: 'services',
    url: 'services/',
    methods: {
        generateIdentifier: {
            url: 'get_identifier/'
        },
        setBestPractice: {
            url: 'set-best-practice/'
        }
    },
}
