import React, { FC, useState } from "react";
import { IOrganization } from "../../models/ILifeSituation";
import "./styles.scss";
import Buttons from "../Buttons/Buttons";
import LifeSituationServiceItem from "../LifesituationsComponent/LifeSituationServiceItem/LifeSituationServiceItem";

interface OrganizationsComponentProps {
    organizations: IOrganization[] | undefined;
    lifeSituationOption: any;
}

const OrganizationsComponent: FC<OrganizationsComponentProps> = ({
                                                                     organizations,
                                                                     lifeSituationOption,
                                                                 }) => {
    const [selectedCards, setSelectedCards] = useState<string[]>([]);

    const toggleCardSelection = (id: string) => {
        if (selectedCards.includes(id)) {
            setSelectedCards(selectedCards.filter((cardId) => cardId !== id));
        } else {
            setSelectedCards([...selectedCards, id]);
        }
    };

    return (
        <div className="lifeCituationContainer">
            {organizations && organizations.length > 0 ? (
                organizations.map((item) => (
                    <div
                        key={item.id}
                        className={`lifeContinaerItem  ${
                            item?.id && selectedCards.includes(item.id) ? "active" : ""
                        }`}
                    >
                        <div className="cardLifeSituation">
                            <div
                                className="cardHeader"
                                onClick={() => item?.id && toggleCardSelection(item.id)}
                            >
                                <h3 className="cardHeaderSubtitle">
                                    {item.subname || "Организация"}
                                </h3>
                                <h1 className="cardHeaderTitle">{item.name}</h1>
                            </div>
                            <div className="cardFooter">
                                <p className="cardFooterNumber">{item.code || ""}</p>
                                <div className="containerButtonCard">
                                    {item.is_best_practice_service_count && (
                                        <Buttons
                                            key={`${item.id}-best-practice`}
                                            className="myButton"
                                            text={`${item.is_best_practice_service_count}` || ""}
                                            toolTip={"Кол-во лучших практик"}
                                            onClick={() => {}}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                        {selectedCards.includes(item.id ? item.id : "") && (
                            <LifeSituationServiceItem
                                readonly={true}
                                key={`${item.id}-service-items`}
                                lifeSitaitonsId={item.id}
                                services={item.services}
                                servicesOption={
                                    lifeSituationOption?.find(
                                        (option: { key: string }) => option.key === "list"
                                    )?.value
                                }
                            />
                        )}
                    </div>
                ))
            ) : (
                <div className="noLifeSituationsMessage">Ничего нет</div>
            )}
        </div>
    );
};

export default OrganizationsComponent;