import React, {FC, useState} from "react";
import icons from "../../assets/icons/icons";
import Buttons from "../Buttons/Buttons";
import "./styles.scss";
import {useDispatch} from "react-redux";
import {AuthActionCreators} from "../../store/reducers/auth/action-creator";
import {useTypeSelector} from "../../hooks/useTypedSelector";
import LifesituationsApiRequest from "../../api/Lifesituation/Lifesituation";
import apiConfig from "../../api/apiConfig";
import UploadFileComponent from "../UploadFileComponent/UploadFileComponent";

const Header: FC = () => {
    const dispatch = useDispatch();
    // const { user } = useTypeSelector((state) => state.authReducer);
    const storedUser = localStorage.getItem("user");
    const user = storedUser ? JSON.parse(storedUser) : null;
    const lifeSituationApi = new LifesituationsApiRequest();
    const {orgId} = useTypeSelector((state) => state.lifeSituationReducer);

    const lifeSituationDownload = (allOrganization: boolean) => {
        lifeSituationApi
            .dataDownload(
                `?organization=${orgId !== "" ? orgId : user.organization}${
                    allOrganization ? "&is_all=is_all" : ""
                }`
            )
            .then((resp) => {
                if (resp.success && resp.data) {
                    const link = document.createElement("a");
                    //@ts-ignore
                    link.href = `${apiConfig.baseUrlMedia}${resp.data.url}`;
                    link.download = "";
                    link.target = "_blank";
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link); // Удаляем ссылку после скачивания
                }
            })
            .catch((error) => {
                console.error("Error downloading file:", error);
            });
    };

    return (
        <header className="header">
            <div className="containerHeader">
                <div className="headerLogoText">
                    <img src={icons.Logo}/>
                    <p>
                        Реестр межведомственных и внутриведомственных процессов
                        исполнительных и государственных органов Белгородской области
                    </p>
                </div>
                <div className="userHeaderContainer">
                    {user?.is_admin && (
                        <Buttons
                            className="whiteButton"
                            text={"Скачать реестр всех организаций"}
                            onClick={() => {
                                lifeSituationDownload(true);
                            }}
                        />
                    )}
                    <Buttons
                        className="whiteButton"
                        text={"Скачать реестр"}
                        onClick={() => {
                            lifeSituationDownload(false);
                        }}
                    />
                    {user?.is_admin && (
                        <UploadFileComponent/>
                    )}


                    <div className="userName">
                        <p className="lastname">{user?.lastname}</p>
                        <p>{`${user?.firstname} ${user?.patronymic}`}</p>
                    </div>

                    <Buttons
                        className="whiteButton"
                        ico={icons.logOut}
                        text={"Выход"}
                        //@ts-ignore
                        onClick={() => dispatch(AuthActionCreators.logout())}
                    />
                </div>
            </div>
        </header>
    );
};

export default Header;
